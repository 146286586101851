/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { sharedRef } from '@vue-storefront/core';
import { OrderEmbionOrderDataHeader, OrderEmbionOrderData, OrderEmbyonOrderDocuments, OrderEmbyonOrderDocument } from '~/helpers/middleware-client';
import { InternalEndpoints } from '~/enums/internalEndpoints';
import axios from 'axios';
import { utils, writeFileXLSX } from 'xlsx';
import { ref } from '@nuxtjs/composition-api';
import { useI18n } from '~/helpers/hooks/usei18n';

const useEmbionOrders = (customerId: string) => {
  const loadingOrders = sharedRef<boolean>(false, `loading-orders-${customerId}`);
  const error = sharedRef<Error>(null, `error-${customerId}`);
  const orders = sharedRef<OrderEmbionOrderDataHeader[]>(null, `orders-${customerId}`);
  const notShippedOrders = sharedRef<OrderEmbionOrderDataHeader[]>(null, `not-shipped-orders-${customerId}`);
  const orderDetail = sharedRef<OrderEmbionOrderData>(null, `order-detail-${customerId}`);
  const totalRecords = sharedRef<number>(null, `total-orders-${customerId}`);
  const totalRecordsNotShipped = sharedRef<number>(null, `total-orders-not-shipped-${customerId}`);
  const totalPages = sharedRef<number>(null, `total-pages-${customerId}`);
  const totalPagesNotShipped = sharedRef<number>(null, `total-pages-not-shipped-${customerId}`);
  const showBreakdown = sharedRef<string>(null, `show-breakdown-${customerId}`);
  const documents = sharedRef<OrderEmbyonOrderDocuments>(null, `documents-${customerId}`);
  const trans = useI18n();

  const generateDocumentPath = (orderNumber: string, documentType: string): string => {
    const filePath = `${orderNumber.padStart(12, '0')}000000000000.pdf`;
    const documentPath = `https://extranet.colfert.com/Documents_PDF_Files/COLFERT/${documentType}/${filePath}`;
    return documentPath;
  };

  const exportExcel = (orderHeader, orderItems) => {
    const workbook = utils.book_new();
    const parsedOrderHeader = orderHeader;
    delete parsedOrderHeader.documentState;
    delete parsedOrderHeader.specialistCode;
    delete parsedOrderHeader.documentExpenses;
    delete parsedOrderHeader.documentEffectExpenses;
    delete parsedOrderHeader.documentOtherExpenses;
    delete parsedOrderHeader.documentTaxableTotal;
    delete parsedOrderHeader.documentRemainingTaxableTotal;
    delete parsedOrderHeader.documentPackagingCharges;
    delete parsedOrderHeader.codeAppearanceGoods;
    delete parsedOrderHeader.carriageCode;
    delete parsedOrderHeader.unconditionalDiscount;
    delete parsedOrderHeader.unconditionalDiscountPercentage;
    delete parsedOrderHeader.transportCause;
    delete parsedOrderHeader.packaging;
    delete parsedOrderHeader.documentVector;
    delete parsedOrderHeader.carriage;
    delete parsedOrderHeader.shippingBy;
    delete parsedOrderHeader.documents;
    const translatedParsedOrderHeader = [];
    Object.entries(parsedOrderHeader).forEach(([key, value]) => {
      const newKey = `${trans.t(key)}`;
      translatedParsedOrderHeader[`${newKey}`] = value;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (value.units !== null && value.units !== undefined && value.micros !== null && value.micros !== undefined) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        translatedParsedOrderHeader[`${newKey}`] = `${value.units}.${value.micros}`;
      }
    });
    const headerWorksheet = utils.json_to_sheet([translatedParsedOrderHeader]);
    const parsedOrderItems = orderItems.embionData.map((item) => {
      const newItem = item;
      delete newItem.vatCode;
      delete newItem.totalRowRemaining;
      delete newItem.rowWarehouse;
      delete newItem.commercialReference;
      delete newItem.originProgressive;
      delete newItem.originRowId;
      delete newItem.supplierCode;
      delete newItem.trackingCode;
      Object.keys(newItem).forEach((key) => {
        newItem[`${trans.t(key)}`] = newItem[key];
        if (newItem[key].units !== null && newItem[key].units !== undefined && newItem[key].micros !== null && newItem[key].micros !== undefined) {
          newItem[`${trans.t(key)}`] = `${newItem[key].units}.${newItem[key].micros}`;
        }
      });
      delete newItem.rowId;
      delete newItem.rowType;
      delete newItem.productCode;
      delete newItem.productDescription;
      delete newItem.umManaged;
      delete newItem.quantityManaged;
      delete newItem.quantityManagedRemaining;
      delete newItem.unitPriceNet;
      delete newItem.totalRow;
      delete newItem.rowClosed;
      delete newItem.supplier;
      return newItem;
    });
    const productsWorkSheet = utils.json_to_sheet(parsedOrderItems);
    utils.book_append_sheet(workbook, headerWorksheet, 'Testata');
    utils.book_append_sheet(workbook, productsWorkSheet, 'Prodotti');
    writeFileXLSX(workbook, `${orderHeader.documentNumber}.xlsx`, { compression: true });
  };

  const loadOrders = async (companyCode: string, { keyword = null, pageSize = 20, page = 1 }) => {
    try {
      orders.value = null;
      const params = {
        companyCode,
        ...(pageSize && { pageSize }),
        ...(page && { page }),
        ...(keyword && { keyword }),
      };
      loadingOrders.value = true;
      const { data, status } = await axios.post(InternalEndpoints.GetOrderList, {
        ...params,
      });
      if (status === 200) {
        orders.value = data.orders.map((order) => ({ ...order, documentPath: generateDocumentPath(order.id, order.documentType) }));
        totalRecords.value = Number.parseInt(data.totalRecords, 10);
        totalPages.value = Number.parseInt(data.totalPages, 10);
      } else {
        error.value = data;
      }
    } catch (jsError) {
      console.error('loadOrders ~ error:', jsError);
    }
    loadingOrders.value = false;
  };

  const getDocuments = async (companyCode: string, orderNumber: string | string[]) => {
    const orderDocuments = ref<OrderEmbyonOrderDocument[]>([]);
    const DOCUMENTS_TYPE = ['DOCUMENT_TYPE_INVOICE', 'DOCUMENT_TYPE_ORDER', 'DOCUMENT_TYPE_SALE_NOTE'];

    const promises = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const documentType of DOCUMENTS_TYPE) {
      // eslint-disable-next-line no-await-in-loop
      promises.push(
        axios.post(InternalEndpoints.GetOrderDocuments, {
          codiceCliente: companyCode,
          documentType,
          orderIds: typeof orderNumber === 'string' ? [orderNumber] : orderNumber,
        })
      );
    }

    const responses = await Promise.all(promises);

    responses.forEach((response, index) => {
      const { data, status } = response;
      const thisData: OrderEmbyonOrderDocuments = data[Object.keys(data)[0]];
      if (status === 200 && thisData?.documents?.length > 0) {
        orderDocuments.value.push({
          type: DOCUMENTS_TYPE[index],
          url: thisData?.documents[0]?.url || '',
        });
      }
    });
    return orderDocuments.value;
  };

  const getOrdersDocuments = async (companyCode: string, orderNumbers: string[]) => {
    const ordersDocuments = ref<Map<string, OrderEmbyonOrderDocument[]>>(new Map());
    const DOCUMENTS_TYPE = ['DOCUMENT_TYPE_INVOICE', 'DOCUMENT_TYPE_ORDER', 'DOCUMENT_TYPE_SALE_NOTE'];

    const promises = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const documentType of DOCUMENTS_TYPE) {
      // eslint-disable-next-line no-await-in-loop
      promises.push(
        axios.post(InternalEndpoints.GetOrderDocuments, {
          codiceCliente: companyCode,
          documentType,
          orderIds: orderNumbers,
        })
      );
    }

    const responses = await Promise.all(promises);
    const orderDocs = new Map();

    responses.forEach((response, index) => {
      const { data, status } = response;

      // put in ordersDocuments all the received documents using orderId as key
      if (status === 200) {
        Object.keys(data).forEach((orderId) => {
          if (!orderDocs[orderId]) {
            orderDocs[orderId] = [];
          }
          orderDocs[orderId].push(...data[orderId].documents);
          orderDocs[orderId] = orderDocs[orderId].filter((v, i, a) => a.findIndex((t) => t.url === v.url) === i);
        });
      }
    });
    ordersDocuments.value = orderDocs;
    return ordersDocuments.value;
  };

  const loadOrdersWithDocuments = async (companyCode: string, { keyword = null, pageSize = 20, page = 1 }) => {
    const orderNumbers: string[] = [];
    try {
      orders.value = null;
      const params = {
        companyCode,
        ...(pageSize && { pageSize }),
        ...(page && { page }),
        ...(keyword && { keyword }),
      };
      loadingOrders.value = true;
      const { data, status } = await axios.post(InternalEndpoints.GetOrderList, {
        ...params,
      });
      if (status === 200) {
        orders.value = data.orders.map((order) => ({ ...order, documentPath: generateDocumentPath(order.id, order.documentType) }));
        totalRecords.value = Number.parseInt(data.totalRecords, 10);
        totalPages.value = Number.parseInt(data.totalPages, 10);
        orderNumbers.push(...data.orders.map((order) => order.id));
      } else {
        error.value = data;
      }
    } catch (jsError) {
      console.error('loadOrders ~ error:', jsError);
    }

    if (orderNumbers.length === 0) {
      loadingOrders.value = false;
      return;
    }

    const docs = await getOrdersDocuments(companyCode, orderNumbers);
    orders?.value.forEach((order) => {
      order.documents = docs[order.id] || [];
    });

    loadingOrders.value = false;
  };

  const loadNotShippedOrders = async (companyCode: string, { keyword = null, pageSize = 20, page = 1 }) => {
    try {
      orders.value = null;
      const params = {
        companyCode,
        ...(pageSize && { pageSize }),
        ...(page && { page }),
        ...(keyword && { keyword }),
      };
      loadingOrders.value = true;
      const { data, status } = await axios.post(InternalEndpoints.GetNotShippedOrders, {
        ...params,
      });
      if (status === 200) {
        notShippedOrders.value = data.embyonNotShippedData.reduce((acc, curr) => {
          const existingIndex = acc.findIndex((item) => item.documentNumber === curr.documentNumber);

          if (existingIndex === -1) {
            acc.push({
              documentNumber: curr.documentNumber,
              documentType: curr.documentType,
              documentDate: curr.documentDate,
              notShippedItems: [...curr.embyonNotShippedItems],
            });
          } else {
            acc[existingIndex].notShippedItems.push(...curr.embyonNotShippedItems);
          }

          return acc;
        }, []);
        totalRecordsNotShipped.value = Number.parseInt(data.totalRecords, 10);
        totalPagesNotShipped.value = Number.parseInt(data.totalPages, 10);
      } else {
        error.value = data;
      }
    } catch (jsError) {
      console.error('loadOrders ~ error:', jsError);
    }
    loadingOrders.value = false;
  };

  const getOrder = async (orderNumber: string, companyCode: string) => {
    // documents.value = await getDocuments(companyCode, orderNumber);
    loadingOrders.value = true;
    const { data, status } = await axios.post(InternalEndpoints.GetOrder, {
      orderNumber,
    });
    if (status === 200) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      orderDetail.value = {
        embionData: data?.embionOrderItems?.items || null,
        geminiData: (data?.geminiOrderData?.id && data?.geminiOrderData) || null,
      };
    }
    error.value = data;
    loadingOrders.value = false;
  };

  return {
    error,
    orders,
    documents,
    totalRecords,
    totalPages,
    orderDetail,
    loadingOrders,
    loadOrders,
    loadOrdersWithDocuments,
    getOrder,
    exportExcel,
    getDocuments,
    showBreakdown,
    loadNotShippedOrders,
    notShippedOrders,
    totalRecordsNotShipped,
    totalPagesNotShipped,
  };
};

export default useEmbionOrders;
