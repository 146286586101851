import { useContext } from '@nuxtjs/composition-api';

const MARKET_GRN = 'grn:channelmanager:market::6511a49ca2b6895296e73fd1';

export const useGeminiConfiguration = () => {
  const context = useContext();

  const loadConfiguration = () => {
    const state = context?.app?.$vsf?.$gemini?.config?.state;
    const market = context?.$config?.marketGrn || MARKET_GRN; // fallback to hardcoded market
    if (state) {
      state.setMarket(market);
      state.setAcceptLanguage('it-IT');
      state.setCurrency('EUR');
    }
  };

  return {
    loadConfiguration,
  };
};
